<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="900"
    class="PersonnelSelectionDialog"
    top="5vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    :before-close="close"
  >
    <template v-if="position === 'induction' || this.position === 'performance'">
      <el-tabs
        style="margin-top: 16px"
        v-model="buttonActive"
        @tab-click="changeButton"
      >
        <el-tab-pane label="系统内员工" name="1"></el-tab-pane>
        <el-tab-pane label="系统外员工" name="2"></el-tab-pane>
      </el-tabs>
    </template>

    <div class="content" style="height: 100%;">
      <div class="PersonnelSelectionDialog__title">
        <el-form
          :inline="true"
          size="mini"
          :model="formInline"
          class="demo-form-inline"
          label-position="top"
        >
          <template v-if="buttonActive == '1'">
            <el-form-item label="HFM Code">
              <el-select
                v-model="formInline.hfmCode"
                clearable
                placeholder="HFM Code"
              >
                <el-option
                  v-for="item in HFMList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Name/tel">
              <el-input
                v-model="formInline.userName"
                placeholder="Name/tel"
              ></el-input>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="Name">
              <el-input
                v-model="formInline.name"
                placeholder="Name"
              ></el-input>
            </el-form-item>
          </template>

          <el-form-item label=" . ">
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button  @click="onAddShow" v-if="buttonActive == '2'">新增</el-button>
          </el-form-item>
        </el-form>
      </div>

      <vxe-table
        ref="dataTable"
        :loading="loading"
        height="300"
        :scroll-y="scrollY"
        show-header-overflow
        show-overflow="title"
        :key="formKey"
        row-id="id"
        :checkbox-config="checkboxConfig"
        :radio-config="radioConfig"
        header-row-class-name="vxe-hearder"
        header-cell-class-name="vxe-header-cell"
        @checkbox-change="handleSelectionChange"
        @checkbox-all="handleSelectionChange"
        @radio-change="getCurrentRow"
        border="none"
      >
        <vxe-column v-if="!radio" type="checkbox" width="55"></vxe-column>
        <vxe-column v-else type="radio" width="55"></vxe-column>
        <vxe-column
          v-for="item in ColumnConfig"
          v-if="item.visible"
          :key="item.key"
          :title="item.name"
          :field="item.key"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        ></vxe-column>
      </vxe-table>
      <!-- 新增分页器 暂时隐藏-->
      <!-- <el-pagination
        class="page-area"
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page.sync="pageConfig.pageNum"
        layout="sizes, prev, pager, next, jumper"
        :total="pageConfig.total">
      </el-pagination> -->
      <!-- 原表格 -->
      <!-- <PocTable
        ref="dataTable"
        v-loading="loading"
        :data="tableData"
        max-height="400"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :style="radio ? { cursor: 'pointer' } : null"
        @row-click="rowClick"
        hidepage
        @selection-change="handleSelectionChange"
        @size-change="pageSizeChange"
        :key="formKey"
        @page-current-change="pageChange"
      >
        <el-table-column v-if="!radio" type="selection" width="55">
        </el-table-column>
        <el-table-column v-else label="选择" align="center" width="55">
          <template v-slot="scope">
            <el-radio
              :label="scope.$index"
              v-model="radioModel"
              @change.native="getCurrentRow(scope.row)"
              >&nbsp;</el-radio
            >
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in columnConfig"
          show-overflow-tooltip
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        ></el-table-column>
      </PocTable> -->
      <div class="transferStatusContent" v-if="position === 'transferStatus'">
        <div class="transferStatusContent__title">附件及其它</div>
        <div class="transferStatusContent__attachments">
          <pgUploadFile :limit="3" :id="id" type="Transfer" />
        </div>
        <div class="transferStatusContent__hc">
          <el-checkbox v-model="hcIsBroughtOut">HC是否带出</el-checkbox>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button
        type="primary"
        v-if="position === 'transferStatus'"
        @click="staging"
        >暂 存</el-button
      >
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
    <el-dialog
      :title="addTitle"
      :visible.sync="addDialogVisible"
      width="450px"
      class="PersonnelSelectionAddDialog"
      top="140px"
      append-to-body
      destroy-on-close
      :before-close="addClose"
    >
      <el-form
        ref="addForm"
        :model="addForm"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="Lion ID of LM" prop="lionId">
          <el-input v-model="addForm.lionId"></el-input>
        </el-form-item>
        <el-form-item label="Name of LM" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="LM Email" prop="email">
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
        <el-form-item label="LM(Level 1)" prop="lmLevel1">
          <el-input v-model="addForm.lmLevel1"></el-input>
        </el-form-item>
        <el-form-item label="LM(Level 2)" prop="lmLevel2">
          <el-input v-model="addForm.lmLevel2"></el-input>
        </el-form-item>
        <el-form-item label="LM(Level 3)" prop="lmLevel3">
          <el-input v-model="addForm.lmLevel3"></el-input>
        </el-form-item>
        <el-form-item label="HRBP of LM" prop="lmHrbp">
          <el-input v-model="addForm.lmHrbp"></el-input>
        </el-form-item>
      </el-form>
      <div class="addform-tags">适用于LM在国外，不在系统中，无法选择到的情况</div>
      <div slot="footer">
        <el-button @click="addClose">取 消</el-button>
        <el-button type="primary" @click="addSubmit">保 存</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { columnConfig, columnSystemConfig, columnNonSystemConfig } from '@/components/PersonnelSelectionDialog/columnConfig'
import {
  getBeforeRegular,
  getHfmCodeList,
  getOrganizationsUser,
  getTaUserList,
  getTransferUserList
} from '@/api/organization'
import { getUserList } from '@/api/quto'
import { getLeaverName, getLineManager, getOutList, saveOutMember } from '@/api/intern'
import pgUploadFile from '@/components/pg-uploadFile/pg-uploadFile'

import { debounce } from '@/utils/util'

export default {
  name: 'PersonnelSelectionDialog',
  mixins: [$PCommon.TableMixin],
  components: {
    pgUploadFile
  },
  props: {
    hfmCode: {
      type: String
    }
  },
  data() {
    return {
      dialogVisible: false,
      HFMList: [],
      loading: false,
      formKey: Math.random(),
      columnConfig,
      columnSystemConfig,
      columnNonSystemConfig,
      selectVal: [],
      tableData: [],
      formInline: {
        hfmCode: '',
        userName: '',
        userType: ''
      },
      id: '',
      radio: false,
      radioModel: '',
      hfmCodeOne: '',
      hcIsBroughtOut: false,
      position: 'Personnel',
      title: '人员选择',
      scrollY: {
        enabled: true,
        gt: 100,
      },
      checkboxConfig: {
        reserve: true,
        checkField: 'isChecked',
        checkAll: false
      },
      radioConfig: {
        checkRowKey: 'isChecked'
      },
      rowStyleConfig: {
        height: 39.89
      },
      // 新增
      addTitle: '新增LM',
      addDialogVisible: false,
      addForm: {
        lionId: '',
        name: '',
        email: '',
        lmLevel1: '',
        lmLevel2: '',
        lmLevel3: '',
        lmHrbp: '',
      },
      buttonActive: '1',
      rules: {
        lionId: [
          { required: true, message: '请输入Lion ID of LM', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入Name of LM', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入LM Email', trigger: 'blur' }
        ],
        lmLevel1: [
          { required: true, message: '请输入LM(Level 1)', trigger: 'blur' }
        ],
        lmLevel2: [
          { required: true, message: '请输入LM(Level 2)', trigger: 'blur' }
        ],
        lmHrbp: [
          { required: true, message: '请输入HRBP of LM ', trigger: 'blur' }
        ],
      }
    }
  },
  computed: {
    ColumnConfig() {
      return this.position == 'induction' || this.position === 'performance' ? (this.buttonActive == '1' ? this.columnSystemConfig : this.columnNonSystemConfig) : this.columnConfig
    }
  },
  mounted() {
  },
  methods: {
    changeButton(tab, event) {
      this.buttonActive = tab.name;
      this.pageConfig.pageNum = 1;
      this.tableData = [];
      (debounce(this.getData, 500))()
      // this.getData();
      this.$forceUpdate()
    },
    // pageSizeChange(val) {
    //   this.pageConfig.pageSize = val;
    //   this.getData();
    // },
    // pageChange(val) {
    //   this.pageConfig.pageNum = val;
    //   this.getData()
    // },
    // 虚拟表格
    checkMethod(row) {
      return !row.row.hcDisable
    },
    getData() {
      this.loading = true
      if (this.position === 'Personnel') {
        this.queryApi()
      } else if (this.position === 'sendTA') {
        this.queryTaApi()
      } else if (this.position === 'quto') {
        this.queryQutoApi()
      } else if (this.position === 'intern') {
        this.queryInternApi()
      } else if (this.position === 'leaver') {
        this.queryLeaverApi()
      } else if (this.position === 'hrbp') {
        this.queryHrbpApi()
      } else if (this.position === 'transferStatus') {
        this.queryTransferStatusApi()
      } else if (this.position === 'beforeRegular') {
        this.queryBeforeRegular()
      } else if (this.position === 'induction' || this.position === 'performance') {
        this.buttonActive == '1' ? this.queryInductionData() : this.queryOutData()
      }
    },
    queryBeforeRegular() {
      const data = {
        ...this.formInline
      }
      getBeforeRegular(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data) {
            this.tableData = res.data;
            this.tableData.forEach(i => i.isChecked = false);
            this.$refs.dataTable.reloadData(this.tableData).then(() => {
            })
          } else {
            this.tableData = [];
          }
        }
      })
    },
    // 外籍人员数据
    queryOutData() {
      const data = {
        ...this.formInline
      }
      getOutList(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data) {
            this.tableData = res.data;
            this.tableData.forEach(i => i.isChecked = false);
            this.$refs.dataTable.reloadData(this.tableData).then(() => {
            })
          } else {
            this.tableData = [];
          }
        }
      })
    },
    //招聘管理界面过来的数据
    queryInductionData() {
      const data = {
        ...this.formInline
        // hfmCode: this.hfmCode
      }
      getLineManager(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data) {
            this.tableData = res.data;
            this.tableData.forEach(i => i.isChecked = false);
            this.$refs.dataTable.reloadData(this.tableData).then(() => {
            })
          } else {
            this.tableData = [];
          }
        }
      })
    },
    queryHrbpApi() {
      const data = {
        ...this.formInline
      }
      getOrganizationsUser(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data) {
            this.tableData = res.data;
            this.tableData.forEach(i => i.isChecked = false);
            this.$refs.dataTable.reloadData(this.tableData).then(() => {
            })
          } else {
            this.tableData = [];
          }
        }
      })
    },
    queryLeaverApi() {
      const data = {
        ...this.formInline
      }
      getLeaverName(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data) {
            this.tableData = res.data;
            this.tableData.forEach(i => i.isChecked = false);
            this.$refs.dataTable.reloadData(this.tableData).then(() => {
            })
          } else {
            this.tableData = [];
          }
        }
      })
    },
    queryInternApi() {
      const data = {
        ...this.formInline,
        hfmCode: this.formInline.hfmCode,
        hfmCodeOne: this.hfmCodeOne
      }
      if(!data.hfmCode){
        data.hfmCode = this.formInline.hfmCode
      }
      getLineManager(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data) {
            this.tableData = res.data;
            this.tableData.forEach(i => i.isChecked = false);
            this.$refs.dataTable.reloadData(this.tableData).then(() => {
            })
          } else {
            this.tableData = [];
          }
        }
      })
    },
    queryApi() {
      const data = {
        ...this.formInline
      }
      getOrganizationsUser(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data) {
            this.tableData = res.data;
            this.tableData.forEach(i => i.isChecked = false);
            this.$refs.dataTable.reloadData(this.tableData).then(() => {
            })
          } else {
            this.tableData = [];
          }
        }
      })
    },
    queryTransferStatusApi() {
      const data = {
        ...this.formInline
      }
      getTransferUserList(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data) {
            this.tableData = res.data;
            this.tableData.forEach(i => i.isChecked = false);
            this.$refs.dataTable.reloadData(this.tableData).then(() => {
            })
          } else {
            this.tableData = [];
          }
        }
      })
    },
    queryTaApi() {
      const data = {
        ...this.formInline
      }
      getTaUserList(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data) {
            this.tableData = res.data;
            this.tableData.forEach(i => i.isChecked = false);
            this.$refs.dataTable.reloadData(this.tableData).then(() => {
            })
          } else {
            this.tableData = [];
          }
        }
      })
    },
    queryQutoApi() {
      const data = {
        ...this.formInline
      }
      getUserList(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data) {
            this.tableData = res.data;
            this.tableData.forEach(i => i.isChecked = false);
            this.$refs.dataTable.reloadData(this.tableData).then(() => {
            })
          } else {
            this.tableData = [];
          }
        }
      })
    },
    close() {
      this.tableData = []
      this.radio = false
      this.formInline = {
        hfmCode: '',
        userName: '',
        userType: ''
      }
      this.selectVal = []
      this.hcIsBroughtOut = false
      this.dialogVisible = false
      this.radioModel = false
    },
    handleSelectionChange(val) {
      this.selectVal = val.records;
      this.$forceUpdate();

    },
    getCurrentRow(val) {
      console.log(val.row)
      this.selectVal = [val.row];
      this.$forceUpdate();
    },
    show(val) {
      if (val && val.position) {
        this.position = val.position
      }
      if (val && val.type) {
        this.formInline.userType = val.type
      }
      getHfmCodeList().then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.HFMList = res.data
          } else {
            this.HFMList = []
          }
        }
      })
      this.id = val.id || ''
      this.radio = val.radio || false
      this.hfmCodeOne = val.hfmCode || ''
      this.title = val.title || ''
      this.getData()
      this.formKey = Math.random()
      this.dialogVisible = true
    },
    staging() {
      let data = {
        selectVal: this.selectVal,
        hcIsBroughtOut: this.hcIsBroughtOut
      }
      this.$emit('onStaging', data)
      this.close()
    },
    submit() {
      if (this.position === 'transferStatus') {

        let data = {
          selectVal: this.selectVal,
          hcIsBroughtOut: this.hcIsBroughtOut
        }
        this.$emit('onConfirm', data)
        this.close()
      } else {
        let data = null;
        if(this.position == 'induction') {
          data = {
            lineManagerLiid: this.buttonActive == '1' ? this.selectVal[0].lineManagerLiid : this.selectVal[0].lionId,
            englishName: this.buttonActive == '1' ? this.selectVal[0].englishName : this.selectVal[0].name,
            email: this.selectVal[0].email,
            lineManagerBaseId: this.buttonActive == '1' ? null : this.selectVal[0].id,
            //  this.buttonActive.buttonActive == 1 系统内员工;this.buttonActive.buttonActive == 2 外部员工
            // ceoType1 (0内部员工,1是外部员工)")
            ceoType1: this.buttonActive == '1' ? 0 : 1,
          }
          this.$emit('onConfirm', [data])
        } else if(this.position == 'performance') {
          data = {
            lineManagerLiid: this.buttonActive == '1' ? this.selectVal[0].lineManagerLiid : this.selectVal[0].lionId,
            englishName: this.buttonActive == '1' ? this.selectVal[0].englishName : this.selectVal[0].name,
            email: this.selectVal[0].email,
            lineManagerPerformanceBaseId: this.buttonActive == '1' ? null : this.selectVal[0].id
          }
          this.$emit('onConfirm', [data])
        } else {
          data = this.selectVal
          this.$emit('onConfirm', data)
        }
        this.close()
      }
    },
    rowClick(val) {
      if (this.radio) {
        this.selectVal = [val]
      }
    },
    onSearch() {
      this.getData()
    },
    onAddShow() {
      this.addDialogVisible = true;
      this.addForm = {
        lionId: '',
        name: '',
        email: ''
      };
    },
    addClose() {
      this.addDialogVisible = false;
    },
    addSubmit() {
      /* this.$emit('onConfirm', [this.addForm],1)
      this.addClose()
      this.close() */
      this.$refs['addForm'].validate((validate) => {
        if(validate) {
          console.log('验证通过')
          this.saveOutMember()
        }
      })
    },
    saveOutMember() {
      let data = {
        ...this.addForm
      }
      saveOutMember(data).then(res => {
        if (res && res.code === 200) {
          this.$message.success('新增成功')
          this.addClose()
          this.getData()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.PersonnelSelectionDialog {
  border-radius: 4px;
  overflow: hidden;

  :deep(.el-dialog__title) {
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #303133;
  }
  :deep(.el-dialog__body) {
    padding: 0 20px 0 20px;

    overflow-x: hidden;
    overflow-y: scroll;
  }
  .transferStatusContent {
    &__title {
      //padding-left: 10px;
      font-size: 15px;
      font-weight: bold;
      padding-bottom: 10px;
      //border-bottom: 1px solid gainsboro;
      margin-top: 30px;
    }
    &__attachments {
      margin-top: 10px;
      //margin-left: 20px;
    }
    &__hc {
      margin-top: 20px;
      //margin-left: 20px;
    }
  }
  :deep(.vxe-hearder) {
    height: 32.44px;
    .vxe-header-cell{
      height: 100%;
      font-size: 14px!important;
      font-family: Roboto!important;
      color: #ba9765!important;
      line-height: 13px;
    }

  }
  :deep(.vxe-body--row){
    .vxe-body--column{
      .vxe-cell{
        font-size: 12px!important;
        font-family: Roboto!important;
        padding-left: 14px;
        color: #4e4e50!important;
      }
    }
  }
}
.addform-tags{
  color: #ccc;
  font-size: 14px;
}

// 分页样式
.page-area{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  :deep(.el-pagination__sizes) {
    flex: 1;
  }
}
:deep(.el-form .el-form-item--mini.el-form-item) {
  margin-bottom: 24px!important;
}
</style>
