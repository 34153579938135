import { role } from '@/components/ScopeTransfer/Hooks/role'
import { pre } from '@/components/ScopeTransfer/Hooks/pre'
import { preRole } from '@/components/ScopeTransfer/Hooks/preRole'
import { org } from '@/components/ScopeTransfer/Hooks/org'
import { secret } from './secret'
import { adduser } from './adduser'
import { disRole } from './disRole'
import { fundsList } from './fundsList'
import { fundsList2 } from './fundsList2'

import { disOrg } from './disOrg'

const recursionTree = (arr, data, id) => {
  if (arr && arr.length) {
    arr.forEach((item) => {
      if (item.children && item.children.length) {
        recursionTree(item.children, data, id)
      }
      data.push(item[id])
    })
  }
}

export default { org, pre, preRole, role, recursionTree, secret, adduser, disRole, fundsList, fundsList2,disOrg }
