import {
  sysPermissionsGetAssignList,
  sysPermissionsGetUnAssignList
} from '@/api/Transfer'
import {
  isCodeSuccess,
  isCodeToSuccess
} from '@/components/ScopeTransfer/Hooks/general'

export const pre = (ctx) => {
  ctx.loading = true
  sysPermissionsGetUnAssignList(ctx.id).then((res) => {
    isCodeSuccess(res, ctx)
    ctx.from_data.forEach(a=>menuSort(a))
    ctx.loading = false
  })
  sysPermissionsGetAssignList(ctx.id).then((res) => {
    isCodeToSuccess(res, ctx)
    ctx.from_data.forEach(a=>menuSort(a))
    ctx.loading = false
  })
}

const menuSort = (list)=>{
  if(list.children && list.children.length > 0){
    list.children.sort((a,b)=>{return a.showNum - b.showNum})
    list.children.forEach(a=> menuSort(a))
  }
}
