import fundsListApi2 from '@/api/funds'
import {
  isCodeSuccess,
  isCodeToSuccess
} from '@/components/ScopeTransfer/Hooks/general'
  
export const fundsList2 = (ctx) => {
  ctx.loading = true
  fundsListApi2.getorgListUn(ctx.id).then((res) => {
    
    isCodeSuccess(res, ctx)
    ctx.loading = false
  })
  fundsListApi2.orgList(ctx.id).then((res) => {
    
    isCodeToSuccess(res, ctx)
    ctx.loading = false
  })
}
