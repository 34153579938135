<template>
  <div class="PGTitle">
    <div class="title">{{ title }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PGTitle',
  props: ['title']
}
</script>

<style lang="scss" scoped>
.PGTitle {
  .title {
    //border-bottom: 1px solid darkgray;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #303133;
    padding-bottom: 12px;
    margin-top: 20px;
  }
}
</style>
