export const columnConfig = [
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'userName',
    name: '用户名',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: null // 默认宽度，像素
  },
  {
    key: 'roleName',
    name: '岗位',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: null // 默认宽度，像素
  },
  {
    key: 'mobile',
    name: '手机号',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: null // 默认宽度，像素
  },
  {
    key: 'email',
    name: '邮箱',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: null // 默认宽度，像素
  }
]

export const columnSystemConfig = [
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'englishName',
    name: 'English Name',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'userName',
    name: 'Chinese Name',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'roleName',
    name: '岗位',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'email',
    name: '邮箱',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
]

export const columnNonSystemConfig = [
  {
    key: 'lionId',
    name: 'Lion ID',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'name',
    name: 'English Name',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150, // 默认宽度，像素

  },
  {
    key: 'email',
    name: 'Business Email',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 250 // 默认宽度，像素
  }
]
