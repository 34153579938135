<template>
  <div class="FileUpload">
    <el-upload
      ref="uploadRef"
      class="upload-demo"
      action="#"
      :auto-upload="false"
      :show-file-list="false"
      :onChange="onChange"
      :disabled="disabled"
    >
      <template #trigger>
        <div class="upload-icon" v-show="limit !== 0">
          <svg-icon icon="addUp"></svg-icon>
          <slot></slot>
        </div>
      </template>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'FileUpload',
  components: {},

  props: {
    disabled: {
      type: Boolean,
      default: () => false
    },
    limit: {
      type: Number
    }
  },
  methods: {
    onChange(val) {
      console.log("val",val)
      this.$emit('onChange', val)
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.FileUpload {
  width: 80px;
  height: 80px;
  .upload-icon {
    width: 80px;
    height: 80px;
    background: rgb(250, 250, 250);
    color: $--color-button-primary !important;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    line-height: 80px;
    border: 1px dashed #dbdbdb;
  }
}
</style>
