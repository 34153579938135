import {
  sysUserGetRoleAssignList,
  sysUserGetRoleUnAssignList
} from '@/api/Transfer'
import {
  isCodeSuccess,
  isCodeToSuccess
} from '@/components/ScopeTransfer/Hooks/general'

export const role = (ctx) => {
  ctx.loading = true
  sysUserGetRoleUnAssignList(ctx.id).then((res) => {
    isCodeSuccess(res, ctx)
    ctx.loading = false
  })
  sysUserGetRoleAssignList(ctx.id).then((res) => {
    isCodeToSuccess(res, ctx)
    ctx.loading = false
  })
}
