<!-- 滑块组件 -->
<template>
  <div class="poc-slider">
    <div class="poc-slider-box" :style="getStyle">
      <slot></slot>
    </div>
    <div class="poc-slider-btns" v-show="isShow">
      <slot v-if="$slots.btns" name="btns"></slot>
      <el-button
        v-else
        :type="btntype"
        @click="packupData = !packupData"
        :class="packupData ? 'on' : 'off'"
        icon="el-icon-d-arrow-left"
      ></el-button>
      <!-- type="primary" <i class="poc-icon-primary nicon el-icon-d-arrow-left" @click="packup=!packup" :class="packup ? 'on' : 'off'"></i> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PocSlider',

  props: {
    // 收起，默认展开
    packup: {
      type: Boolean,
      default: true
    },
    minheight: {
      type: String,
      default: '56px'
    },
    maxHeight: {
      type: String,
      default: '300px'
    },
    // 按钮类型 默认无样式 可选：primary/success/info/warning/danger
    btntype: {
      type: String,
      default: ''
    },
    // 查询条件只有一行时隐藏按钮
    isShow: {
      type: Boolean,
      default: true
    },
  },
  watch: {
    packup: {
      handler(val) {
        this.packupData = val
      },
      deep: true
    }
  },
  data() {
    return {
      packupData: this.packup || true
    }
  },

  computed: {
    getStyle() {
      let maxHeight = this.packupData ? this.minheight : this.maxHeight
      return {
        maxHeight: maxHeight
      }
    }
  },

  methods: {}
}
</script>

<style lang="scss">
.poc-slider {
  width: 100%;
  // display: flex;

  .poc-slider-box {
    flex: 1;
    transition: max-height 0.3s linear;
    overflow: hidden;
  }
  .poc-slider-btns {
    // width: 36px;
    position: relative;
    .el-button {
      position: absolute;
      right: 0;
      width: 28px;
      height: 28px;
      margin-top: 28px !important;
      padding: 5px 0;

      &.on .el-icon-d-arrow-left {
        transform: rotate(-90deg);
      }
      &.off .el-icon-d-arrow-left {
        transform: rotate(90deg);
      }
    }
    .el-icon-d-arrow-left {
      transition: all 0.3s linear;
      // color: $--color-primary;
      // font-size: 12px;
      // font-weight: 400;
    }
  }
}
</style>
