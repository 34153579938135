import fundsListApi from '@/api/funds'
import {
  isCodeSuccess,
  isCodeToSuccess
} from '@/components/ScopeTransfer/Hooks/general'
  
export const fundsList = (ctx) => {
  ctx.loading = true
  fundsListApi.getorgListUn(ctx.id).then((res) => {
    const _func = (item) => {
      item.disabled = true;
      if (item.children && item.children.length) {
        item.children.map(c=> {
          _func(c);
        })
      }
    }
    res.data.map((item) => {
      _func(item)
    })
    isCodeSuccess(res, ctx)
    ctx.loading = false
  })
  fundsListApi.orgList(ctx.id).then((res) => {
    const _func = (item) => {
      item.disabled = true;
      if (item.children && item.children.length) {
        item.children.map(c=> {
          _func(c);
        })
      }
    }
    res.data.map((item) => {
      _func(item)
    })
    isCodeToSuccess(res, ctx)
    ctx.loading = false
  })
}
