import { sysRoleGetUnAssignList, sysRoleGetAssignList } from '@/api/Transfer'
import {
  isCodeSuccess,
  isCodeToSuccess
} from '@/components/ScopeTransfer/Hooks/general'

export const preRole = (ctx) => {
  ctx.loading = true
  sysRoleGetUnAssignList(ctx.id).then((res) => {
    isCodeSuccess(res, ctx)
    ctx.loading = false
  })
  sysRoleGetAssignList(ctx.id).then((res) => {
    isCodeToSuccess(res, ctx)
    ctx.loading = false
  })
}
