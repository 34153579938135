<template>
  <div class="descriptions">
    <el-descriptions
      v-for="(descriptions, index) in descriptionData"
      :key="index"
      :column="descriptions.column"
      :title="descriptions.title"
    >
      <el-descriptions-item
        v-for="descriptionsItem in descriptions.content"
        :key="descriptionsItem.label"
        :label="descriptionsItem.label"
        :span="descriptionsItem.span || 1"
      >
        <el-popover
          class="item"
          title=""
          trigger="hover"
          width="300"
          popper-class="workorder-reason-popper"
          :content="String(descriptionsItem.value)"
          placement="top-start"
        >
          <span slot="reference"> {{ descriptionsItem.value }}</span>
        </el-popover>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: 'descriptions',
  props: {
    descriptionData: {
      required: true
    }
  },
  watch: {
    descriptionData: {
      handler(val) {
        this.list = val
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      list: []
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-descriptions__body .el-descriptions__table .el-descriptions-row .el-descriptions-item{
  line-height: 1.5;
}

</style>
