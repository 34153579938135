<template>
  <div class="FormDesigner">
    <el-form
      :inline="true"
      class="BasicInfo-form"
      ref="BasicInfoRef"
      size="mini"
      :key="formKey"
      :label-position="'top'"
    >
      <ValidationObserver ref="ValidationObserverRef">
        <el-row
          v-for="rowItem in formDataList"
          :key="rowItem.id"
          :gutter="rowItem.gutter"
        >
          <el-col
            v-for="colItem in rowItem.colList"
            :key="colItem.id"
            :style="{ overflow: colItem.formData.tooltip ? 'hidden' : null }"
            :span="colItem.span"
          >
            <el-form-item
              :label="colItem.formData.label"
              :required="isRequired(colItem.formData)"
            >
              <el-tooltip
                v-if="colItem.formData.tooltip"
                class="item"
                effect="dark"
                :content="colItem.formData.label"
                placement="top"
              >
                <ValidationProvider
                  mode="passive"
                  :rules="colItem.formData.rules"
                  v-slot="{ classes, errors }"
                >
                  <div
                    class="control"
                    :class="classes"
                    v-if="colItem.formData.type === 'select'"
                  >
                    <el-select
                      v-model="colItem.formData.value"
                      :clearable="colItem.formData.clearable"
                      :filterable="colItem.formData.filterable"
                      :placeholder="colItem.formData.placeholder"
                      @change="colItem.formData.change"
                    >
                      <el-option
                        v-for="item in colItem.formData.options"
                        :key="item.value+Math.random()"
                        :label="item.label"
                        @click.native="colItem.formData.onClick(item)"
                        :value="item.value"
                      />
                    </el-select>
                    <span v-if="errors.length">{{ errors[0] }}</span>
                  </div>
                  <div
                    class="control"
                    :class="classes"
                    v-if="colItem.formData.type === 'input'"
                  >
                    <el-input
                      v-model="colItem.formData.value"
                      :type="colItem.formData.disType"
                      :placeholder="colItem.formData.placeholder"
                      @click.native="colItem.formData.onClick ? colItem.formData.onClick() : () => {}"
                    ></el-input>
                    <span v-if="errors.length">{{ errors[0] }}</span>
                  </div>
                  <div
                    class="control"
                    :class="classes"
                    v-if="colItem.formData.type === 'data'"
                  >
                    <el-date-picker
                      v-model="colItem.formData.value"
                      :type="
                        !colItem.formData.disType
                          ? 'date'
                          : colItem.formData.disType
                      "
                      :value-format="colItem.formData.valueFormat"
                      @change="colItem.formData.change"
                      :placeholder="colItem.formData.placeholder"
                    />
                    <span v-if="errors.length">{{ errors[0] }}</span>
                  </div>
                  <div
                    class="control"
                    :class="classes"
                    v-if="colItem.formData.type === 'inputNumber'"
                  >
                    <el-input-number
                      v-model="colItem.formData.value"
                    ></el-input-number>
                    <span v-if="errors.length">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </el-tooltip>
              <ValidationProvider
                v-else
                mode="passive"
                :rules="colItem.formData.rules"
                v-slot="{ classes, errors }"
              >
                <div
                  class="control"
                  :class="classes"
                  v-if="colItem.formData.type === 'select'"
                >
                  <el-select
                    v-model="colItem.formData.value"
                    :clearable="colItem.formData.clearable"
                    :filterable="colItem.formData.filterable"
                    :placeholder="colItem.formData.placeholder"
                    :disabled="colItem.formData.disabled"
                    @change="colItem.formData.change"
                  >
                    <el-option
                      v-for="item in colItem.formData.options"
                      :key="item.value + Math.random()"
                      :label="item.label"
                      @click.native="colItem.formData.onClick(item)"
                      :value="item.value"
                    />
                  </el-select>
                  <span v-if="colItem.formData.description"
                        style="font-size: 12px;color: #1d1e20"
                  >{{colItem.formData.description}}</span>
                  <span v-if="errors.length">{{ errors[0] }}</span>
                </div>
                <div
                  class="control"
                  :class="classes"
                  v-if="colItem.formData.type === 'input'"
                >
                  <el-input
                    v-model="colItem.formData.value"
                    :type="colItem.formData.disType"
                    :disabled="colItem.formData.disabled"
                    :placeholder="colItem.formData.placeholder"
                    :readonly="['lionIdOfLineManager', 'lineManager', 'performanceManager', 'lionIdOfPerformanceManager', 'nameOfPerformanceLm'].includes(colItem.formData.prop)"
                    @click.native="colItem.formData.onClick ? colItem.formData.onClick() : () => {}"
                  ></el-input>
                  <span v-if="errors.length">{{ errors[0] }}</span>
                </div>
                <div
                  class="control"
                  :class="classes"
                  v-if="colItem.formData.type === 'data'"
                >
                  <el-date-picker
                    v-model="colItem.formData.value"
                    :type="
                      !colItem.formData.disType
                        ? 'date'
                        : colItem.formData.disType
                    "
                    :disabled="colItem.formData.disabled"
                    :value-format="colItem.formData.valueFormat"
                    @change="colItem.formData.change"
                    :placeholder="colItem.formData.placeholder"
                  />
                  <span v-if="errors.length">{{ errors[0] }}</span>
                </div>
                <div
                  class="control"
                  :class="classes"
                  v-if="colItem.formData.type === 'inputNumber'"
                >
                  <el-input-number
                    :disabled="colItem.formData.disabled"
                    v-model="colItem.formData.value"
                  ></el-input-number>
                  <span v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </el-form-item>
          </el-col>
        </el-row>
      </ValidationObserver>
    </el-form>
  </div>
</template>

<script>
import { checkOrganization } from '@/api/organization'

export default {
  name: 'FormDesigner',
  props: {
    formScale: {
      type: Array,
      required: true
    }
  },
  watch: {
    formScale: {
      handler(val) {
        if (val) {
          this.formDataList = val || []
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      formDataList: [],
      formKey: Math.random(),
      isRightOrg: true
    }
  },
  methods: {
    changeKey() {
      this.formKey = Math.random()
    },
    setOptions(obj, formScale, excepts = []) {
      if (obj) {
        Object.keys(obj).forEach((item) => {
          {
            let name = item
            let value = obj[item]
            this.formDataList.forEach((rowItem) => {
              rowItem.colList.forEach((colItem) => {
                if (colItem.formData.type === 'select' && !excepts.includes(colItem.formData.prop)) {
                  if(colItem.formData.prop === name && ['lineManager','nameOfVegaLm','performanceManager'].includes(name)){
                    value.forEach(a=>{
                      a.value = a.label
                    })
                    colItem.formData.options = value
                  }else if (colItem.formData.prop === name) {
                    colItem.formData.options = value
                  }
                }
              })
            })
          }
        })
        this.changeKey()
      }
    },
    clearAllValue() {
      this.formDataList.forEach((rowItem) => {
        rowItem.colList.forEach((colItem) => {
          colItem.formData.value = ''
        })
      })
      this.changeKey()
    },
    setValSign(name, value, formScale) {
      this.formDataList.forEach((rowItem) => {
        rowItem.colList.forEach((colItem) => {
          if (colItem.formData.prop === name) {
            colItem.formData.value = value
          }
        })
      })
      this.changeKey()
    },
    setDescriptionSign(name, value, formScale) {
      this.formDataList.forEach((rowItem) => {
        rowItem.colList.forEach((colItem) => {
          if (colItem.formData.prop === name) {
            colItem.formData.description = value
          }
        })
      })
      this.changeKey()
    },
    setValue(obj) {
      if (obj) {
        Object.keys(obj).forEach((item) => {
          {
            let name = item
            let value = obj[item]
            this.formDataList.forEach((rowItem) => {
              rowItem.colList.forEach((colItem) => {
                if (colItem.formData.prop === name) {
                  colItem.formData.value = value
                  // colItem.formData.change
                  //   ? colItem.formData.change(value, true)
                  //   : null
                }
              })
            })
          }
        })
        this.changeKey()
      }
    },
    getSingleVal(name) {
      let val = ''
      this.formDataList.forEach((rowItem) => {
        rowItem.colList.forEach((colItem) => {
          if (colItem.formData.prop === name) {
            val = colItem.formData.value || ''
          }
        })
      })
      return val
    },
    changeRules(name, value, formScale) {
      this.formDataList.forEach((rowItem) => {
        rowItem.colList.forEach((colItem) => {
          if (colItem.formData.prop === name) {
            colItem.formData.rules = value
          }
        })
      })
      this.changeKey()
    },
    changeOptions(name, value, formScale) {
      this.formDataList.forEach((rowItem) => {
        rowItem.colList.forEach((colItem) => {
          if (colItem.formData.prop === name) {
            colItem.formData = { ...colItem.formData, ...value }
          }
        })
      })
      this.changeKey()
    },
    SumObj() {
      let obj = {}
      this.formDataList.forEach((rowItem) => {
        rowItem.colList.forEach((colItem) => {
          obj[colItem.formData.prop] = colItem.formData.value
        })
      })
      return obj
    },
    isRequired(val) {
      if (val.rules.length) {
        return val.rules.includes('required')
      } else {
        return false
      }
    },
    async getValue() {
      let validate = false
      await this.$refs.ValidationObserverRef.validate().then((success) => {
        validate = !!success
      })
      if (validate) {
        // let res = await this.checkOrganization();
        // if(res) {
        //   return this.SumObj()
        // }
        return this.SumObj()
      } else {
        return validate
      }
    },
    get() {
      this.getValue()
    },
    async checkOrganization() {
      let dataList = [];
      this.formDataList.forEach(i => dataList.push(...i.colList));
      let targetData = dataList.filter(i => ['hfmCode', 'legalEntity', 'assigmentProjectLevel2', 'location'].includes(i.formData.prop))
      if(!targetData[0].formData.value || !targetData[1].formData.value || !targetData[2].formData.value || !targetData[3].formData.value) {
        this.isRightOrg = true;
        return this.isRightOrg;
      } else {
        let params = {};
        targetData.forEach(i => {
          if (i.formData.prop == 'hfmCode') {
            params.hfm_code = i.formData.value
          }
          if (i.formData.prop == 'legalEntity') {
            params.legalEntity = i.formData.value.split('_')[1]
          }
          if (i.formData.prop == 'assigmentProjectLevel2') {
            params.assigmentProjectId = i.formData.value
          }
          if (i.formData.prop == 'location') {
            params.workLoaction = i.formData.value
          }
        })
        let result = await checkOrganization(params).then(res => {
          if(res && res.code == 200){
            res.data ? (this.isRightOrg = true) : (this.$message.error('所选机构已禁用！',this.isRightOrg = false))
          } else {
            this.isRightOrg = false;
          }
          return this.isRightOrg;
        })
        return result
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.FormDesigner {
  .control {
    width: 100%;
    span {
      display: block;
    }
  }
  .control.is-invalid {
    input {
      border: 1px #eb0600 solid;
    }
    span {
      display: block;
      font-size: 12px;
      color: #eb0600;
    }
  }
  .control.is-valid {
    input {
      border: 1px #67c23a solid;
      color: #67c23a;
    }
    span {
      display: block;
      color: #67c23a;
    }
  }
}
</style>
