import addUserApi from '@/api/dataMain'
import {
  isCodeSuccess,
  isCodeToSuccess
} from '@/components/ScopeTransfer/Hooks/general'
  
export const adduser = (ctx) => {
  ctx.loading = true
  addUserApi.getOrgUnAssignList(ctx.id).then((res) => {
    isCodeSuccess(res, ctx)
    ctx.loading = false
  })
  addUserApi.getOrgAssignList(ctx.id).then((res) => {
    isCodeToSuccess(res, ctx)
    ctx.loading = false
  })
}
