<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="treeData.visibleTmpl"
    :before-close="close"
    append-to-body
    destroy-on-close
    width="35%"
    custom-class="add_menu_dialog"
  >
    <tree-transfer
      v-loading="treeData.loading"
      :from_data="treeData.from_data"
      :to_data="treeData.toData"
      :defaultProps="treeData.defaultProps"
      @add-btn="add"
      :pid="treeData.parentId"
      :node_key="treeData.node_key"
      @remove-btn="remove"
      height="330px"
      :checkStrictly="treeData.checkStrictly"
      filter
      show-checkbox
      :title="treeData.treeTitle"
      :openAll="treeData.openAll"
      :placeholder="treeData.placeholder"
      class="tarnsfer_tree"
      rootPidValue="0"
      disabled
    >
      <!-- :check-strictly="true" -->
      <!-- rootPidValue="0"穿梭框模式，根节点数据pid的值，用于匹配退出循环， -->
    </tree-transfer>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="mini" v-if="fromPage != 'fundsPage'"
        >取 消</el-button
      >
      <el-button
        @click="handleSave"
        size="mini"
        type="primary"
        class="buttonActive"
      >
        <span v-if="fromPage == 'fundsPage'">关 闭</span>
        <span v-else>完 成</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import treeTransfer from 'el-tree-transfer'
import treeApi from '@/components/ScopeTransfer/Hooks'
export default {
  components: {
    treeTransfer
  },
  props: {
    dialogTitle: {
      type: String,
      required: true,
      default: '分配权限'
    },
    fromPage: {
      type: String
    }
  },
  data() {
    return {
      treeData: {
        // 弹窗显示/隐藏
        visibleTmpl: false,
        checkStrictly: false,
        // 别名
        defaultProps: {
          children: 'children',

          label: 'name'
        },
        loading: false,
        // 右侧
        toData: [],
        // 左侧
        from_data: [],
        // 是否默认展开全部
        openAll: true,
        // 选中的数据
        selected: [],
        // 左右树的title
        treeTitle: ['待分配角色', '已分配角色'],
        placeholder: '请输入角色名称',
        // 自定义 pid参数名，因为后端给的参数名为id
        parentId: this.fromPage == 'fundList' ? 'orgParentId' : 'parentId',
        // 当前显示的是组织 角色,权限角色还是权限
        /*
              角色      role,
              组织      org
              权限角色   preRole
              权限     pre
         */
        positions: '',
        id: '',
        node_key: this.fromPage == 'fundList' ? 'orgId' : 'id'
      }
    }
  },
  methods: {
    initTreeList() {
      treeApi[this.treeData.positions](this.treeData)
    },
    show(data) {
      if (data) {
        this.treeData.positions = data.positions
        this.treeData.id = data.id
        if (data.checkStrictly) {
          this.treeData.checkStrictly = data.checkStrictly
        }
        if (data.treeTitle) {
          this.treeData.treeTitle = data.treeTitle
        }
        if (data.placeholder) {
          this.treeData.placeholder = data.placeholder
        }
        this.initTreeList()
      }
      this.treeData.visibleTmpl = true
    },
    close() {
      this.treeData.visibleTmpl = false
    },
    // 监听穿梭框组件添加
    add(fromData, toData, obj) {
      this.treeData.selected = toData
    },
    // 监听穿梭框组件移除
    remove(fromData, toData, obj) {
      this.treeData.selected = toData
      //  this.treeData.selected = ''
    },
    handleSave() {
      const arr = []
      treeApi.recursionTree(this.treeData.selected, arr, this.treeData.node_key)
      if (arr.length) {
        this.$emit('onConfirm', arr.reverse().toString(','))
      } else {
        if (this.treeData.toData) {
          //没有穿梭框的添加移除，需要判断toData是否有自带值
          treeApi.recursionTree(
            this.treeData.toData,
            arr,
            this.treeData.node_key
          )
          this.$emit('onConfirm', arr.reverse().toString(','))
        } else {
          this.$emit('onConfirm', '')
        }
      }
      this.treeData.visibleTmpl = false
      // close()
    }
  }
}
</script>

<style lang="scss">
.add_menu_dialog {
  //padding: 20px !important;
  //box-sizing: border-box;
  min-width: 676px;
  // height: 50vh;
  //min-height: 470px;
  .iconColor {
    color: #ba9765;
  }
  .el-select {
    width: 100%;
  }
  .el-input__inner {
    width: 100%;
    // padding: 10px 8px 0 8px;
    height: 28px;
    border-radius: 15px;
  }
  .el-dialog__title {
    font-size: 18px;
    color: #303133;
    font-weight: 400 !important;
    margin-left: 10px;
  }
  //.el-icon-close:before {
  //  color: #868686;
  //  font-size: 14px;
  //}
  //.el-dialog__body {
  //  padding: 20px 10px 0 10px !important;
  //}
  //.el-dialog__header {
  //  padding: 0 !important;
  //  box-sizing: border-box;
  //}

  .wl-transfer {
    .transfer-title {
      font-size: 14px;
      background: #f5f7fa;
      text-align: center;
      color: #303133;
      border-radius: 2px 2px 0 0;
      width: 100%;
      height: 30px !important;
      line-height: 30px !important;
      padding: 0 !important;
      .el-checkbox {
        display: none;
      }
    }
    .el-tree {
      max-height: 330px;
      // background: red;
    }
    .transfer-left,
    .transfer-right {
      width: 43%;
      // border: 1px solid red;
      border-radius: 0;
    }
    .transfer-right {
      border-top: 0;
    }
    .transfer-main {
      padding: 10px 8px 10px 8px !important;
      box-sizing: border-box;
    }
    .filter-tree {
      margin-bottom: 19px !important;
    }
  }
  //.el-dialog__footer {
  //  padding: 34px 0 20px 0px !important;
  //  box-sizing: border-box;
  //  .el-button {
  //    margin-left: 24px;
  //  }
  //}

  .open_check {
    position: absolute;
    top: 147px;
    left: 153px;
  }
  .buttonUnactive {
    width: 76px;
    height: 30px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ba9765;
    border-radius: 4px;
    color: #ba9765;
    font-size: 14px;
    line-height: 15px;
  }
  .buttonActive {
    width: 76px;
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 15px;
  }
  h3 {
    margin: 0px !important;
    padding: 0px !important;
  }
  .el-icon-caret-right:before {
    background: #f4f4f4;
    height: 14px;
    content: '';
    width: 14px;
    border-radius: 2px;
  }
  .el-tree-node__expand-icon {
    color: #ba9765;
  }
  .el-button.is-circle {
    width: 36px;
    height: 36px;
    color: #bb9667;
  }
  .el-icon-arrow-left:before {
    font-size: 17px;
    color: #fff;
  }
  .el-icon-arrow-right:before {
    content: '';
    font-size: 17px;
    color: #fff;
  }
}
</style>
