import addUserApi from '@/api/dataMain'
import {
  isCodeSuccess,
  isCodeToSuccess
} from '@/components/ScopeTransfer/Hooks/general'

export const disRole = (ctx) => {
  ctx.loading = true
  Promise.all([
    addUserApi.getRoleUnAssignList(ctx.id).then((res) => {
      isCodeSuccess(res, ctx)
      // ctx.loading = false
    }),
    addUserApi.getRoleAssignList(ctx.id).then((res) => {
      isCodeToSuccess(res, ctx)
      // ctx.loading = false
    })
  ]).then(() => {
    ctx.loading = false
  })
}
