import secretApi from '@/api/secretRole'
import {
  isCodeSuccess,
  isCodeToSuccess
} from '@/components/ScopeTransfer/Hooks/general'
  
export const secret = (ctx) => {
  ctx.loading = true
  secretApi.getOrgUnAssignList(ctx.id).then((res) => {
    isCodeSuccess(res, ctx)
    ctx.loading = false
  })
  secretApi.getOrgAssignList(ctx.id).then((res) => {
    isCodeToSuccess(res, ctx)
    ctx.loading = false
  })
}
