export const isCodeSuccess = (res, ctx) => {
  if (res) {
    if (res.code === 200) {
      if (res.data) {
        // ctx.from_data = res.data
        // ctx.from_data = res.data?.list || (res.data === '[]' ? [] : res.data)
        if(res.data && res.data.list) {
          ctx.from_data = res.data.list
        }else {
          ctx.from_data = res.data === '[]' ? [] : res.data
        }
      } else {
        ctx.from_data = []
      }
    }
  }
}

export const isCodeToSuccess = (res, ctx) => {
  if (res) {
    if (res.code === 200) {
      if (res.data) {
        // ctx.toData = res.data
        // ctx.toData = res.data?.list || (res.data === '[]' ? [] : res.data)
        if(res.data && res.data.list) {
          ctx.toData = res.data.list
        }else {
          ctx.toData = res.data === '[]' ? [] : res.data
        }
      } else {
        ctx.toData = []
      }
    }
  }
}
