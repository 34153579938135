import disOrgApi from '@/api/secretRole'
import {
  isCodeSuccess,
  isCodeToSuccess
} from '@/components/ScopeTransfer/Hooks/general'
  
export const disOrg = (ctx) => {
  ctx.loading = true
  disOrgApi.getRoleOrgUnAssignList(ctx.id).then((res) => {
    isCodeSuccess(res, ctx)
    ctx.loading = false
  })
  disOrgApi.getRoleOrgAssignList(ctx.id).then((res) => {
    isCodeToSuccess(res, ctx)
    ctx.loading = false
  })
}
