<template>
  <div class="OrganizationInformationForm">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      :label-position="'top'"
      class="OrganizationInformationForm__ruleForm"
    >
      <el-row :gutter="24">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form-item label="Solution Hub" prop="solutionHub">
              <el-input disabled v-model="ruleForm.solutionHub"></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="HFM Code" prop="hfmCode">
              <el-input
                :disabled="formStatus"
                v-model="ruleForm.hfmCode"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Internal Brand Name" prop="internalBrandId">
              <el-select
                clearable
                filterable
                :disabled="formStatus"
                v-model="ruleForm.internalBrandId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in internalBrandNameList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Legal Code" prop="legalCode">
              <el-select
                clearable
                filterable
                :disabled="formStatus"
                v-model="ruleForm.legalCode"
                @change="changeLegalCode"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in LegalCodeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Legal Entity Name（英文）" prop="LegalEntityName">
              <el-input
                placeholder="请输入内容"
                v-model="ruleForm.legalEntityEn"
                clearable>
              </el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item
              label="Legal Entity Name（中文）"
              prop="LegalEntityNameZh"
            >
              <el-input
                placeholder="请输入内容"
                v-model="ruleForm.legalEntity"
                clearable>
              </el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Business Unit ID" prop="businessUnitId">
              <el-input
                :disabled="formStatus"
                v-model="ruleForm.businessUnitId"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Business Unit Name" prop="businessUnitName">
              <el-input
                :disabled="formStatus"
                v-model="ruleForm.businessUnitName"
              ></el-input>
            </el-form-item></div
        ></el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="CS Brand ID" prop="csBrandId">
              <el-input
                :disabled="formStatus"
                v-model="ruleForm.csBrandId"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="CS Brand Name" prop="csBrandName">
              <el-input
                :disabled="formStatus"
                v-model="ruleForm.csBrandName"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Work City（英文）" prop="workCityEn">
              <el-select
                clearable
                filterable
                v-model="ruleForm.workCityEn"
                :disabled="formStatus"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in workCityList"
                  :key="item.value"
                  @click.native="changeWorkCity(item)"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Work City（中文）" prop="workCity">
              <el-input
                :disabled="formStatus"
                v-model="ruleForm.workCity"
              ></el-input>
            </el-form-item></div
        ></el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Address（英文）" prop="address">
              <el-input
                :disabled="formStatus"
                v-model="ruleForm.addressEn"
              ></el-input>
              <!--              <el-select-->
              <!--                :disabled="formStatus"-->
              <!--                v-model="ruleForm.addressEn"-->
              <!--                placeholder="请选择"-->
              <!--                @change="changeAdd"-->
              <!--              >-->
              <!--              -->
              <!--              &lt;!&ndash; @click.native="changeAddress(item)" &ndash;&gt;-->

              <!--                <el-option-->
              <!--                  v-for="item in addressList"-->
              <!--                  :key="item.value"-->
              <!--                  :label="item.label"-->
              <!--                  :value="item.value"-->
              <!--                >-->
              <!--                </el-option>-->
              <!--              </el-select>-->
            </el-form-item>
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Address (中文）" prop="addressZh">
              <el-input
                :disabled="formStatus"
                v-model="ruleForm.address"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Postal code" prop="postalCode">
              <el-input
                :disabled="formStatus"
                v-model="ruleForm.postalCode"
              ></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6">
<!--          <el-form-item label=".">-->
<!--            <el-checkbox-->
<!--              label="需CFO审批"-->
<!--              v-model="ruleForm.requireCfoApprove"-->
<!--            />-->
<!--            &nbsp;-->
<!--            &lt;!&ndash;            <span>{{ ruleForm.requireCfoApprove ? '是' : '否' }}</span>&ndash;&gt;-->
<!--          </el-form-item>-->
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="HRBP Level1" prop="hrbpLevelOne">
              <el-input
                :readonly="!formStatus"
                :disabled="formStatus"
                v-model="ruleForm.hrbpLevelOneName"
                @click.native.stop="
                  selectPersonal('hrbpLevelOneName', 'hrbpLevelOne', 'HRBP')
                "
                placeholder="请选择"
              >
              </el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="HRBP Level2" prop="hrbpLevelTwo">
              <el-input
                :readonly="!formStatus"
                :disabled="formStatus"
                v-model="ruleForm.hrbpLevelTwoName"
                @click.native.stop="
                  selectPersonal('hrbpLevelTwoName', 'hrbpLevelTwo', 'HRBP')
                "
                placeholder="请选择"
              >
              </el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="HRBP Level3" prop="hrbpLevelThree">
              <el-input
                :readonly="!formStatus"
                :disabled="formStatus"
                v-model="ruleForm.hrbpLevelThreeName"
                @click.native.stop="
                  selectPersonal('hrbpLevelThreeName', 'hrbpLevelThree', 'HRBP')
                "
                placeholder="请选择"
              >
              </el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Finance Level 1" prop="financeLevelOne">
              <el-input
                :readonly="!formStatus"
                :disabled="formStatus"
                v-model="ruleForm.financeLevelOneName"
                @click.native.stop="
                  selectPersonal(
                    'financeLevelOneName',
                    'financeLevelOne',
                    'Finance1'
                  )
                "
                placeholder="请选择"
              >
              </el-input>
            </el-form-item></div
        ></el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Finance Level 2" prop="financeLevelTwo">
              <el-input
                :readonly="!formStatus"
                :disabled="formStatus"
                v-model="ruleForm.financeLevelTwoName"
                placeholder="请选择"
                @click.native.stop="
                  selectPersonal(
                    'financeLevelTwoName',
                    'financeLevelTwo',
                    'Finance2'
                  )
                "
              >
              </el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Finance Level 3" prop="financeLevelThree">
              <el-input
                :readonly="!formStatus"
                :disabled="formStatus"
                v-model="ruleForm.financeLevelThreeName"
                @click.native.stop="
                  selectPersonal(
                    'financeLevelThreeName',
                    'financeLevelThree',
                    'Finance3'
                  )
                "
                placeholder="请选择"
              >
              </el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Finance Level 4" prop="financeLevelFour">
              <el-input
                :readonly="!formStatus"
                :disabled="formStatus"
                v-model="ruleForm.financeLevelFourName"
                @click.native.stop="
                  selectPersonal(
                    'financeLevelFourName',
                    'financeLevelFour',
                    'Finance4'
                  )
                "
                placeholder="请选择"
              >
              </el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="CTO" prop="cto">
              <el-input
                :readonly="!formStatus"
                :disabled="formStatus"
                v-model="ruleForm.ctoName"
                @click.native.stop="selectPersonal('ctoName', 'cto', 'CTO')"
                placeholder="请选择"
              >
              </el-input>
            </el-form-item></div
        ></el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6"
        ><div class="grid-content bg-purple">
          <el-form-item label="Ops" prop="ops">
            <el-input
              :readonly="!formStatus"
              :disabled="formStatus"
              v-model="ruleForm.opsName"
              @click.native.stop="selectPersonal('opsName', 'ops', 'HROPS')"
              placeholder="请选择"
            >
            </el-input>
          </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="PayRoll" prop="payroll">
              <el-input
                :readonly="!formStatus"
                :disabled="formStatus"
                v-model="ruleForm.payrollName"
                @click.native.stop="
                  selectPersonal('payrollName', 'payroll', 'PAYROLL')
                "
                placeholder="请选择"
              >
              </el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="IT" prop="it">
              <el-input
                :readonly="!formStatus"
                :disabled="formStatus"
                v-model="ruleForm.itName"
                @click.native.stop="selectPersonal('itName', 'it', 'IT')"
                placeholder="请选择"
              >
              </el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple">
            <el-form-item label="Admin" prop="admin">
              <el-input
                :readonly="!formStatus"
                :disabled="formStatus"
                v-model="ruleForm.adminName"
                @click.native.stop="
                  selectPersonal('adminName', 'admin', 'ADMIN')
                "
                placeholder="请选择"
              >
              </el-input>
            </el-form-item></div
        ></el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6"
        ><div class="grid-content bg-purple">
          <el-form-item label="CEO" prop="cto">
            <el-input
              :readonly="true"
              v-model="ruleForm.ceoName"
              clearable
              @click.native.stop="
                  selectPersonal('ceoName', 'ceo', 'CEO')
                "
              placeholder="请选择"
            >
              <template #suffix v-if="ruleForm.ceoName">
                <i class="el-icon-close" @click.stop="ruleForm.ceoName='';ruleForm.ceo=''"></i>
              </template>
            </el-input>
          </el-form-item></div
        ></el-col>
      </el-row>
    </el-form>
    <div class="raf_approval_box">
      <div class="title">RAF审批流设置</div>
      <div class="line">
      </div>
      <div class="flex" style="margin-top: 22px">
        <div style="margin-left: 32px;margin-right:20px;">
          <i class="line_node"></i>
          <div class="checkbox_box">
            <el-checkbox v-model="requireHRBPApprove" disabled>HRBP提交</el-checkbox>
          </div>
        </div>

        <div style="margin-right: 20px;;">
          <i class="line_node"></i>
          <div class="checkbox_box">
            <el-checkbox v-model="requireHRBPLeaderApprove" disabled>HRBP Leader提交</el-checkbox>
          </div>
        </div>
        <div style="margin-right: 20px;;">
          <i class="line_node"></i>
          <div class="checkbox_box">
            <el-checkbox v-model="ruleForm.requireFinanceLevelOneApprove">Finance Level 1提交</el-checkbox>
          </div>
        </div>
        <div style="margin-right: 20px;">
          <i class="line_node"></i>
          <div class="checkbox_box">
            <el-checkbox v-model="ruleForm.requireFinanceLevelTwoApprove" >Finance Level 2提交</el-checkbox>
          </div>
        </div>
        <div style="margin-right:20px;">
          <i class="line_node"></i>
          <div class="checkbox_box">
            <el-checkbox v-model="ruleForm.requireCtoApprove">CTO审批</el-checkbox>
          </div>
        </div>
        <div style="margin-right: 20px;">
          <i class="line_node"></i>
          <div class="checkbox_box">
            <el-checkbox v-model="ruleForm.rafRequireCfoApprove" >Groupe CFO 提交</el-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="raf_approval_box">
      <div class="title">Offer审批流设置</div>
      <div class="line"></div>
      <div class="flex" style="margin-top: 22px">
        <div style="margin-left: 32px;margin-right: 20px">
          <i class="line_node"></i>
          <div class="checkbox_box">
            <el-checkbox v-model="requireFinanceLevel2Approve" disabled>TA提交</el-checkbox>
          </div>
        </div>
        <div style="margin-right:20px">
          <i class="line_node"></i>
          <div class="checkbox_box">
            <el-checkbox v-model="requireGroupeCFOApprove" disabled>HRBP Leader审核</el-checkbox>
          </div>
        </div>
        <div style="margin-right: 20px">
          <i class="line_node"></i>
          <div class="checkbox_box">
            <el-checkbox v-model="ruleForm.requireCfoApprove" >Groupe CFO审批</el-checkbox>
          </div>
        </div>
      </div>

    </div>
    <PersonnelSelectionDialog
      ref="PersonnelSelectionDialogRef"
      @onConfirm="onConfirm"
    />
  </div>
</template>

<script>
import {
  getDataByWorkCity,
  getLegalEntity,
  getLegalEntityCode,
  getWorkCity,
  organizationDetail
} from '@/api/organization'
import PersonnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'
import { check, typeRange } from '@/utils/util'
import { getAssigmentProjectLevel2 } from '@/api/personnelRequisition'
export default {
  name: 'OrganizationInformationForm',
  components: {
    PersonnelSelectionDialog
  },
  props: {
    formStatus: {
      type: Boolean,
      required: true
    },
    orgId: {
      type: String | Number,
      required: true
    },
    solutionHub: {
      required: true
    },
    position: {
      type: String
    }
  },
  created() {
    Object.keys(this.ruleForm).forEach((item) => {
      this.rules[item] = [{ required: true }]
    })
  },
  watch: {
    formStatus: {
      handler(val) {
        this.queryApi(this.position)
      },
      deep: true,
      immediate: true
    },
    orgId: {
      handler(val) {
        if (!this.formStatus) {
          this.ruleForm.orgId = val
          // this.ruleForm.id = val
        }
      },
      immediate: true,
      deep: true
    },
    solutionHub: {
      handler(val) {
        // console.log('solutionHubsolutionHub', val)
        if (val) {
          this.ruleForm.solutionHub = val.solution_hub_name
          this.ruleForm.solutionHubId = val.solution_hub_id
        }
      },
      immediate: true,
      deep: true
    }
    // position: {
    //   handler(val) {
    //     if (val) {
    //       this.positionType = val
    //
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  mounted() {
    this.ruleFormRef = this.$refs.ruleForm
  },

  data() {
    return {
      ruleFormRef: null,
      requireHRBPApprove:true,
      requireHRBPLeaderApprove:true,
      requireFinanceLevel1Approve:true,
      requireFinanceLevel2Approve:true,
      requireGroupeCFOApprove:true,
      ruleForm: {
        solutionHub: '',
        solutionHubId: '',
        hfmCode: '',
        internalBrandName: '',
        internalBrandId: '',
        legalCode: '',
        legalEntityEn: '',
        legalEntity: '',
        businessUnitId: '',
        businessUnitName: '',
        csBrandId: '',
        csBrandName: '',
        workCityEn: '',
        workCity: '',
        address: '',
        addressEn: '',
        postalCode: '',
        hrbpLevelOne: '',
        hrbpLevelTwo: '',
        hrbpLevelThree: '',
        financeLevelOne: '',
        financeLevelTwo: '',
        financeLevelThree: '',
        financeLevelFour: '',
        hrbpLevelOneName: '',
        hrbpLevelTwoName: '',
        hrbpLevelThreeName: '',
        financeLevelOneName: '',
        financeLevelTwoName: '',
        financeLevelThreeName: '',
        financeLevelFourName: '',
        opsName: '',
        payrollName: '',
        itName: '',
        adminName: '',
        ceoName: '',
        ceo:'',
        id: '',
        ops: '',
        orgId: '',
        payroll: '',
        it: '',
        admin: '',
        zipCode: '',
        rafRequireCfoApprove: true,
        requireCfoApprove: false,
        requireCtoApprove: false,
        requireFinanceLevelOneApprove: false,
        requireFinanceLevelTwoApprove: false,
        ctoName: '',
      },
      rules: {},
      LegalCodeList: [],
      LegalEntityNameList: [],
      LegalEntityNameZhList: [],
      internalBrandNameList: [],
      addressList: [],
      workCityList: [],
      positionType: '',

      targetObj: '',
      toObj: '',
      userType: ''
    }
  },
  methods: {
    changeAdd(e) {
      this.addressList.forEach((item) => {
        if (e == item.value) {
          this.ruleForm.address = item.valueCn
        }
      })
    },
    getAddress() {
      getDataByWorkCity({ workCity: this.ruleForm.workCity }).then((res) => {
        if (res && res.code === 200) {
          this.addressList = res.data.addressEnList || []
          // this.ruleForm.address = ''
          // this.ruleForm.addressEn = ''
        }
      })
    },
    changeAddress(item) {
      this.ruleForm.addressEn = item.valueCn
    },
    changeWorkCity(val) {
      this.ruleForm.workCity = val.valueCn
      this.getAddress()
    },
    clickLegalEntityName(val) {
      if (val) {
        this.ruleForm.legalEntity = val.valueCn
      }
    },
    changeLegalCode(val) {
      getLegalEntity({ legalCode: val }).then((res) => {
        if (res && res.code === 200) {
          let nameEn = []
          res.data.forEach((item) => {
            nameEn.push(item)
          })
          this.ruleForm.legalEntityEn = ''
          this.LegalEntityNameList = nameEn
        }
      })
    },
    selectPersonal(val1, val2, val3) {
      if (!this.formStatus) {
        this.targetObj = val1
        this.toObj = val2
        this.$refs.PersonnelSelectionDialogRef.show({
          type: val3,
          radio:val3 === 'CEO'
        })
      }
    },
    onConfirm(val) {
      console.log(val)
      // congzhao====
      // if (val && val.length) {
      //   let data = val[0]
      //   this.ruleForm[this.toObj] = data.id
      //   this.ruleForm[this.targetObj] = data.userName
      // }
      // me====
      if (val && val.length) {
        let nameArr = []
        let IdArr = []
        let data = val
        data.map((d) => {
          nameArr.push(d.userName)
          IdArr.push(d.id)
        })
        if (IdArr.length == 1) {
          this.ruleForm[this.toObj] = IdArr.join(',') + ','
          this.ruleForm[this.targetObj] = nameArr.join(',')
        } else {
          this.ruleForm[this.toObj] = IdArr.join(',') + ','
          this.ruleForm[this.targetObj] = nameArr.join(',')
        }
      } else {
        this.ruleForm[this.toObj] = ''
        this.ruleForm[this.targetObj] = ''
      }
    },
    queryApi(positionType) {
      const data = {
        id: this.orgId,
        type: positionType
      }
      organizationDetail(data).then((res) => {
        if (res && res.code === 200) {
          let orgData = res.data
          if (orgData) {
            Object.keys(this.ruleForm).forEach((item) => {
              if (item !== 'orgId') {
                if (check(this.ruleForm[item]) === typeRange.boolean) {
                  this.ruleForm[item] = orgData[item] || false
                } else {
                  this.ruleForm[item] = orgData[item] || ''
                }
              }
            })
            if (!this.ruleForm.solutionHub) {
              this.ruleForm.solutionHub = this.solutionHub.solution_hub_name
            }
            if (!this.ruleForm.solutionHubId) {
              this.ruleForm.solutionHubId = this.solutionHub.solution_hub_id
            }
          }
          this.getAddress()
        }
      })

      getLegalEntityCode().then((res) => {
        if (res && res.code === 200) {
          if (res.data && res.data.length) {
            this.LegalCodeList = res.data
          }
        }
      })
      getAssigmentProjectLevel2().then((res) => {
        if (res && res.code === 200) {
          if (res.data && res.data.length) {
            this.internalBrandNameList = res.data
          }
        }
      })
      getWorkCity().then((res) => {
        if (res && res.code === 200) {
          if (res.data && res.data.length) {
            this.workCityList = res.data
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.OrganizationInformationForm {
  //padding: 30px;
  ::v-deep &__ruleForm {
    .el-row {
      .point {
        cursor: pointer !important;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .raf_approval_box{
    margin-top: 20px;
    padding-bottom: 20px;
    .title{
      font-size: 16px;
      font-weight: 500;
      color: #303133;
    }
    .line{
      position: relative;
      border-color: inherit;
      background-color: #EBEEF5;
      height: 2px;
      top: 12px;
      left: 0;
      right: 0;
      width: 100%;
    }
    .line_node{
      width: 5px;
      height: 5px;
      background: #BB9667;
      border-radius: 50%;
      position: relative;
      display: block;
      top: -13px;
      left: 0;
      right: 0;
    }
    .checkbox_box{
      background: #F4F4F4;
      border-radius: 4px;
      height: 32px;
      padding: 0 8px;
      ::v-deep .el-checkbox__label{
        font-size: 12px;
        font-weight: 500;
        color: #909399;
        line-height: 32px;
      }
    }
    ::v-deep .is-disabled{
      .el-checkbox__inner{
        background-color: #D2BB9B;
      }
      .el-checkbox__inner::after{
        border-color: #FFFFFF;
      }
    }
  }
}
</style>
